import React, { useState, useEffect } from 'react';
import { Heading1 } from './Heading';
import Text from './Text';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

interface CountdownProps {
  targetDate: string;
  targetTime: string;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate, targetTime }) => {
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    const targetDateTime = new Date(`${targetDate}T${targetTime}`);

    const updateCountdown = () => {
      const now = new Date();
      const timeDiff = targetDateTime.getTime() - now.getTime();

      if (timeDiff <= 0) {
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      } else {
        const days = differenceInDays(targetDateTime, now);
        const hours = differenceInHours(targetDateTime, now) % 24;
        const minutes = differenceInMinutes(targetDateTime, now) % 60;
        const seconds = differenceInSeconds(targetDateTime, now) % 60;

        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    updateCountdown(); // Initial call to set the countdown immediately

    return () => clearInterval(interval);
  }, [targetDate, targetTime]);

  return (
    <div className="flex flex-col items-center justify-center -z-10">
      {days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? (
        <Heading1 className="text-3xl font-bold">Time has arrived!</Heading1>
      ) : (
        <div className="flex space-x-4">
          <div className="flex flex-col items-center gap-4">
            <Heading1 className="md:text-5xl font-bold xs:text-3xl">
              {days}
            </Heading1>
            <Text>Days</Text>
          </div>
          <div className="flex flex-col items-center gap-4">
            <Heading1 className="md:text-5xl font-bold xs:text-3xl">
              {hours}
            </Heading1>
            <Text>Hours</Text>
          </div>
          <div className="flex flex-col items-center gap-4">
            <Heading1 className="md:text-5xl font-bold xs:text-3xl">
              {minutes}
            </Heading1>
            <Text>Minutes</Text>
          </div>
          <div className="flex flex-col items-center gap-4">
            <Heading1 className="md:text-5xl font-bold xs:text-3xl">
              {seconds}
            </Heading1>
            <Text>Seconds</Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default Countdown;
