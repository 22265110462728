import React, { useState, useEffect } from 'react';
import Text from './Text';
import axios from 'axios';

interface FormData {
  name: string;
  email: string;
  rsvp: string;
  relationship: string;
  attendance: string;
  gift: string;
}

const Form: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    rsvp: '',
    relationship: '',
    attendance: 'no',
    gift: 'NA',
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(() => {
    return localStorage.getItem('isSubmitted') === 'true';
  });
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [uniqueId, setUniqueId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    localStorage.setItem('isSubmitted', isSubmitted.toString());
  }, [isSubmitted]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        'http://rsvp-website-env.eba-6wjrdyiw.ap-southeast-3.elasticbeanstalk.com/api/generate-qr/',
        formData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      setQrCode(response.data.qrCode);
      setUniqueId(response.data.uniqueId);
      setError(null);
    } catch (error: any) {
      setError(error.response?.data?.error || 'Failed to generate QR code');
      console.error('API call failed:', error.message);
    }
  };

  const generateQrCode = async () => {
    try {
      const response = await axios.post(
        'http://rsvp-website-env.eba-6wjrdyiw.ap-southeast-3.elasticbeanstalk.com/api/generate-qr/',
        formData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      setQrCode(response.data.qrCode);
      setUniqueId(response.data.uniqueId);
      setError(null);
    } catch (error: any) {
      setError(error.response?.data?.error || 'Failed to generate QR code');
      console.error('API call failed:', error.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name" className="">
          <Text>Please introduce your name</Text>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          autoComplete="name"
          value={formData.name}
          onChange={(event) =>
            setFormData({ ...formData, name: event.target.value })
          }
          className="bg-nevada-100 p-1 rounded-sm focus:border-nevada-500 font-playfair"
          disabled={isSubmitted}
        />
        <label htmlFor="email" className="">
          <Text>Please enter your email</Text>
        </label>
        <input
          type="text"
          id="email"
          name="email"
          autoComplete="email"
          value={formData.email}
          onChange={(event) =>
            setFormData({ ...formData, email: event.target.value })
          }
          className="bg-nevada-100 p-1 rounded-sm focus:border-nevada-500 font-playfair"
          disabled={isSubmitted}
        />
        <fieldset disabled={isSubmitted}>
          <legend>
            <Text>Will you be attending Arlene's Sweet 17 Party?</Text>
          </legend>
          <input
            type="radio"
            name="attendance"
            id="yes"
            value="yes"
            checked={formData.rsvp === 'yes'}
            onChange={(event) =>
              setFormData({ ...formData, rsvp: event.target.value })
            }
            className="accent-nevada-500"
          />
          <label htmlFor="yes" className="inline-block px-2">
            <Text>Yes</Text>
          </label>

          <input
            type="radio"
            name="attendance"
            id="no"
            value="no"
            checked={formData.rsvp === 'no'}
            onChange={(event) =>
              setFormData({ ...formData, rsvp: event.target.value })
            }
            className="accent-nevada-500"
          />
          <label htmlFor="no" className="inline-block px-2">
            <Text>No</Text>
          </label>
        </fieldset>
        <fieldset disabled={isSubmitted}>
          <legend>
            <Text>Which one are you?</Text>
          </legend>
          <input
            type="radio"
            name="relationship"
            id="family"
            value="family"
            checked={formData.relationship === 'family'}
            onChange={(event) =>
              setFormData({ ...formData, relationship: event.target.value })
            }
            required
            className="accent-nevada-500"
          />
          <label htmlFor="family" className="inline-flex px-2">
            <Text>Family</Text>
          </label>

          <input
            type="radio"
            name="relationship"
            id="parents-friend"
            value="parents-friend"
            checked={formData.relationship === 'parents-friend'}
            onChange={(event) =>
              setFormData({ ...formData, relationship: event.target.value })
            }
            required
            className="accent-nevada-500"
          />
          <label htmlFor="parents-friend" className="inline-flex px-2">
            <Text>Mr. Aang & Mrs. Anni's Friends</Text>
          </label>

          <input
            type="radio"
            name="relationship"
            id="friend"
            value="friend"
            checked={formData.relationship === 'friend'}
            onChange={(event) =>
              setFormData({ ...formData, relationship: event.target.value })
            }
            required
            className="accent-nevada-500"
          />
          <label htmlFor="friend" className="inline-flex px-2">
            <Text>Arlene's Friends</Text>
          </label>
        </fieldset>
      </form>
      <div className="flex flex-col justify-center">
        <button
          onClick={generateQrCode}
          className="bg-nevada-500  text-pampas-50 py-3 px-8 rounded text-l font-medium font-playfair mt-4 inline-block text-center "
        >
          Submit
        </button>
        {error && <p>Error: {error}</p>}
        {qrCode && (
          <img src={qrCode} alt="QR Code" className="w-auto rounded-3xl" />
        )}
        {uniqueId && <p>Unique ID: {uniqueId}</p>}
      </div>
      <button onClick={() => setIsSubmitted(true)}>Set Submitted</button>
    </>
  );
};

export default Form;
