import React from 'react';
import {
  APIProvider,
  Map,
  Marker,
  InfoWindow,
} from '@vis.gl/react-google-maps';
import { Heading2, Heading3 } from './Heading';

const MyMapComponent: React.FC = () => {
  const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
  const mapCenter = { lat: -6.867977667549596, lng: 107.60724130766282 };
  const infoWindowPosition = {
    lat: -6.867792007951211,
    lng: 107.6072322964013,
  };
  return (
    <div className="h-full w-full -z-10">
      <APIProvider apiKey={API_KEY}>
        <Map
          defaultCenter={mapCenter}
          defaultZoom={18}
          gestureHandling={'greedy'}
        >
          <InfoWindow position={infoWindowPosition}>
            <Heading2 className="hover:underline">
              <a
                href="https://maps.app.goo.gl/Rwd6LdpRKzJiFsaK6"
                target="_blank"
                rel="noopener noreferrer"
              >
                Maxi's Resto
              </a>
            </Heading2>
            <Heading3>Jl. Gunung Agung No.8</Heading3>
          </InfoWindow>
          <Marker position={mapCenter} />
        </Map>
      </APIProvider>
    </div>
  );
};

export default MyMapComponent;
