import React from 'react';

import Navbar from './components/Navbar';
import { Heading1, Heading2, Heading3 } from './components/Heading';
import Text from './components/Text';
import Countdown from './components/Countdown';
import Footer from './components/Footer';
import MyMap from './components/MyMap';
import Form from './components/Form';

import heroImage from './assets/hero-img.png';
import keyImage from './assets/kunci.png';
import fallingImage from './assets/falling-alice.png';
import dresscodeImage from './assets/dresscode-img.jpg';
import endImage from './assets/asset-poci-1.png';
import giftImage from './assets/gift-guide.png';
import pageBorderLeft from './assets/page-border-full-left-cropped.png';
import pageBorderRight from './assets/page-border-full-right-cropped.png';

const App: React.FC = () => {
  return (
    <div className="relative" id="bodyDiv">
      <Navbar />
      <div
        className="max-w-lg mx-auto flex flex-col px-16 gap-32"
        style={{
          backgroundImage: `url(${pageBorderLeft}), url(${pageBorderRight})`,
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundPosition: 'left 0 top 100vh, right 0 top 100vh',
          overflow: 'hidden',
        }}
      >
        {/* Hero Section */}
        <section
          id="main"
          className="flex flex-col justify-center items-center h-screen"
        >
          <img
            src={heroImage}
            alt="hero-img"
            className="object-contain mb-8 w-3/4"
          />
          <Text className="text-center">
            Falling down the rabbit hole into a world of wonder <br />
            🕳️🐇🎩✨
          </Text>
        </section>

        {/* Countdown section */}
        <section
          id="countdown"
          className="flex flex-col justify-center items-center pt-12 "
        >
          <Countdown targetDate="2024-08-25" targetTime="16:30:00" />
        </section>

        <section className="flex justify-center items-center">
          <img src={fallingImage} alt="falling-alice" className="w-1/2 h-1/2" />
        </section>

        {/* Date section */}
        <section
          id="details"
          className="flex flex-col justify-center items-center gap-4"
        >
          <Heading1 className="text-center text-horizon-500">
            You are chosen to explore,
          </Heading1>
          <Heading2 className="text-center text-horizon-500">
            The Sweet Seventeenth of Arlene
            <br />
            Enchanted World Party
          </Heading2>
          <Heading1 className="-z-10">#ARLENEinWonderland</Heading1>
          <Text className="text-center">
            Sunday, August 25th, 2024
            <br />
            The Maxi's Resto
            <br />
            Bandung
            <br />
            Exploring starts at 16.30 sharp!
            <br />
            <br />
            <span className="italic">
              Notes: Avoid using pointy heels in the garden
            </span>
          </Text>
        </section>

        <section className="flex justify-center items-center">
          <img src={keyImage} alt="falling-alice" className="w-1/3 h-1/3" />
        </section>

        {/* Location Section */}
        <section
          id="location"
          className="flex flex-col justify-center items-center gap-4 h-80 px-4"
        >
          <Heading1 className="text-center">Meet us here!</Heading1>
          <MyMap />
        </section>

        {/* Dresscode Section */}
        <section
          id="dresscode"
          className="flex flex-col justify-center items-center gap-4 px-4"
        >
          <Heading1 className="text-center">Dresscode</Heading1>
          <Heading2>Please do take notes!</Heading2>
          <img src={dresscodeImage} alt="dresscode-image" />
        </section>

        {/* Gift Section */}
        <section
          id="gift"
          className="flex flex-col justify-center items-center gap-4 px-4"
        >
          <Heading1 className="text-center">Gift Guide</Heading1>
          <img src={giftImage} alt="gift-image" className="" />
        </section>

        {/* RSVP Section */}
        <section
          id="rsvp"
          className="flex flex-col justify-center items-center gap-4"
        >
          {/* Content of the section */}
          <Heading1 className="text-center">RSVP</Heading1>
          <Heading3 className="text-center text-nevada-500">
            Please fill in the RSVP form to confirm your presence,
          </Heading3>
          <Text className="text-center">
            The White Rabbit is waiting for you!
          </Text>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScuvSZbGn82gwEJTU9vhjgTxrlk3yBDRdd1EuDOSLuLi8C4bQ/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-nevada-500 hover:bg-nevada-700 text-pampas-50 py-3 px-8 rounded text-l font-medium font-playfair mt-4 inline-block text-center"
          >
            RSVP
          </a>
        </section>
        <section>
          <Form />
        </section>
        <section className="flex items-center justify-center  px-8 pt-8 pb-0 mb-0">
          <img src={endImage} alt="poci" className="w-3/4 h-3/4" />
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default App;
