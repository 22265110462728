// src/components/Heading.tsx
import React from 'react';

interface HeadingProps extends React.HTMLProps<HTMLHeadingElement> {
  children: React.ReactNode;
  className?: string;
}

export const Heading1: React.FC<HeadingProps> = ({
  children,
  className = '',
  ...props
}) => (
  <h1
    className={`text-3xl font-bold text-horizon-500 font-playfair ${className}`}
    {...props}
  >
    {children}
  </h1>
);

export const Heading2: React.FC<HeadingProps> = ({
  children,
  className = '',
  ...props
}) => (
  <h2
    className={`text-2xl font-normal text-horizon-500 font-playfair ${className}`}
    {...props}
  >
    {children}
  </h2>
);

export const Heading3: React.FC<HeadingProps> = ({
  children,
  className = '',
  ...props
}) => (
  <h3 className={`text-l font-medium  font-playfair ${className}`} {...props}>
    {children}
  </h3>
);
