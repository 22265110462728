import React, { useState, useEffect } from 'react';
import { Heading3 } from './Heading';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      // Calculate offset to center the section
      const offset = window.innerHeight / 2 - element.clientHeight / 2;
      window.scrollTo({
        top: element.offsetTop - offset,
        behavior: 'smooth',
      });
      closeMenu();
    }
  };

  return (
    <div
      className={`flex flex-row basis-full w-full justify-between p-4 fixed z-10 transition-shadow duration-300 bg-pampas-100 ${
        isScrolled ? 'shadow-lg bg-white' : 'shadow-none bg-transparent'
      }`}
    >
      <div
        className="flex flex-row justify-center items-center"
        onClick={() => scrollToSection('main')}
      >
        <Heading3 className="text-horizon-500">Elixir Organizer</Heading3>
      </div>
      <div className="flex flex-row justify-center items-center">
        <button onClick={toggleMenu} className="text-l">
          {isOpen ? (
            <FaTimes className="fill-horizon-500" />
          ) : (
            <FaBars className="fill-horizon-500" />
          )}
        </button>
      </div>
      {isOpen && (
        <div className="absolute top-16 right-0 bg-white shadow-lg rounded-lg p-4 space-y-2 bg-pampas-100">
          <a
            href="#main"
            className="block text-horizon-500 font-playfair"
            onClick={(event) => {
              event.preventDefault();
              scrollToSection('main');
            }}
          >
            Home
          </a>
          <a
            href="#countdown"
            className="block text-horizon-500 font-playfair"
            onClick={(event) => {
              event.preventDefault();
              scrollToSection('countdown');
            }}
          >
            Countdown
          </a>
          <a
            href="#details"
            className="block text-horizon-500 font-playfair"
            onClick={(event) => {
              event.preventDefault();
              scrollToSection('details'); // Make sure 'details' matches the section ID
            }}
          >
            Event Details
          </a>
          <a
            href="#location"
            className="block text-horizon-500 font-playfair"
            onClick={(event) => {
              event.preventDefault();
              scrollToSection('location');
            }}
          >
            Location
          </a>
          <a
            href="#dresscode"
            className="block text-horizon-500 font-playfair"
            onClick={(event) => {
              event.preventDefault();
              scrollToSection('dresscode');
            }}
          >
            Dress Code
          </a>
          <a
            href="#gift"
            className="block text-horizon-500 font-playfair"
            onClick={(event) => {
              event.preventDefault();
              scrollToSection('gift');
            }}
          >
            Gift Guide
          </a>
          <a
            href="#rsvp"
            className="block text-horizon-500 font-playfair"
            onClick={(event) => {
              event.preventDefault();
              scrollToSection('rsvp');
            }}
          >
            RSVP
          </a>
        </div>
      )}
    </div>
  );
};

export default Navbar;
