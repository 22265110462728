// src/components/Text.tsx
import React from 'react';

interface TextProps {
  children: React.ReactNode;
  className?: string;
  as?: 'p' | 'span'; // Allows you to choose the HTML element type
}

const Text: React.FC<TextProps> = ({
  children,
  className = '',
  as: Component = 'p',
  ...props
}) => (
  <Component
    className={`text-base text-horizon-500 font-playfair ${className}`}
    {...props}
  >
    {children}
  </Component>
);

export default Text;
