import React from 'react';
import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineMail,
} from 'react-icons/ai';
import { Heading3 } from './Heading';

const Footer: React.FC = () => {
  return (
    <div
      id="footer"
      className="flex flex-col basis-full justify-center items-center bg-nevada-500 py-20 h-40 gap-4 z-8 relative"
    >
      <div className="flex justify-between items-center gap-8">
        <a
          href="https://wa.me/6287823120190"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center border border-nevada-50 p-2 rounded-full hover:bg-nevada-600"
        >
          <AiOutlineWhatsApp className="w-8 h-8 text-nevada-50" />
        </a>
        <a
          href="https://www.instagram.com/elixir.organizer/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center border border-nevada-50 p-2 rounded-full hover:bg-nevada-600"
        >
          <AiOutlineInstagram className="w-8 h-8 text-nevada-50" />
        </a>
        <a
          href="mailto:elixir.organizer@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center border border-nevada-50 p-2 rounded-full hover:bg-nevada-600"
        >
          <AiOutlineMail className="w-8 h-8 text-nevada-50" />
        </a>
      </div>
      <Heading3 className="text-nevada-50">Organized by Elixir</Heading3>
    </div>
  );
};

export default Footer;
